@font-face {
  font-family: 'Graphie';
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/GraphieW05-Book.woff2') format('woff2'), url('/fonts/GraphieW05-Book.woff') format('woff');
}
@font-face {
  font-family: 'Graphie';
  font-display: swap;
  font-weight: 800;
  src: url('/fonts/GraphieW05-ExtraBold.woff2') format('woff2'), url('/fonts/GraphieW05-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Graphie';
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/GraphieW05-SemiBold.woff2') format('woff2'), url('/fonts/GraphieW05-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Graphie';
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/GraphieW05-Bold.woff2') format('woff2'), url('/fonts/GraphieW05-Bold.woff') format('woff');
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
.gm-style-cc {
  display: none;
}

#root {
  height: 100%;
  font-family: 'Graphie';
  display: flex;
  flex-direction: column;
}

.btn {
  min-width: 240 !important;
  max-width: 420 !important;
  font-weight: 700 !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center !important;
  font-family: 'Graphie' !important;
}

.btn-contained {
  background-color: #000000 !important;
  color: #ffffff !important;
  text-transform: lowercase;
}
.btn-contained:hover {
  background-color: #ebebeb !important;
  color: #000000 !important;
}

.btn-contained:hover i {
  filter: invert(0) !important;
}

.btn-contained:active {
  background-color: #f45000 !important;
  color: #ffffff !important;
}

.btn-contained:active i {
  filter: invert(1) !important;
}

.btn-contained:disabled {
  opacity: 1 !important;
  background-color: #000000 !important;
  color: #ffffff !important;
}

.btn-contained i {
  filter: invert(1) !important;
}

.btn-outlined {
  outline: 2px solid #000000;
  text-transform: lowercase;
}
.btn-outlined:hover {
  outline: none;
  background-color: #ebebeb !important;
  color: #000000 !important;
}

.btn-outlined:active {
  outline: none;
  background-color: #f45000 !important;
  color: #ffffff !important;
}

.btn-outlined:active i {
  filter: invert(1) !important;
}

.btn-outlined:disabled {
  opacity: 0.4 !important;
  color: #000000 !important;
}

.typography {
  color: #000 !important;
}

.typography.primary {
  color: #f45000 !important;
}

.typography.title1 {
  font-size: 10rem;
  font-weight: 800;
  line-height: 96%;
}
.typography.title2 {
  font-size: 6.5rem;
  font-weight: 800;
  line-height: 96%;
}
.typography.h2bold {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 104%;
}
.typography.h3semibold {
  font-size: 3rem;
  font-weight: 600;
  line-height: 104%;
}
.typography.h4semibold {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 120%;
}
.typography.h5semibold {
  font-size: 2rem;
  font-weight: 600;
  line-height: 128%;
}
.typography.h6book {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 128%;
}
.typography.overline2 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 100%;
}
.typography.bodyS {
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
}
.typography.bodySsemibold {
  font-size: 1rem;
  font-weight: 600;
  line-height: 160%;
}
.typography.bodyXS {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 160%;
}
.typography.bodyXSsemibold {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 160%;
}
.typography.bodysemibold {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 160%;
}
.typography.bodyLsemibold {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 180%;
}
.typography.labelL {
  font-size: 1rem;
  font-weight: 600;
  line-height: 120%;
}
.typography.captionL {
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
}
.typography.captionS {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 120%;
}

.nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dropdown-menu {
  background: #fff !important;
  border-radius: 0px !important;
  border-top: 3px solid #f45000 !important;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%) !important;
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  -webkit-transform: translateZ(0);
  text-align: left !important;
}

.dropdown-item {
  color: #000 !important;
  padding-top: 15px !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 160% !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd !important;
}

.footer {
  background-color: #ebebeb !important;
}

.list-group-item {
  border: 0px !important;
  background-color: #f6f6f6 !important;
  border-color: RGBA(0, 0, 0, 0) !important;
  padding-top: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
  margin-top: 20px !important;
}

.nav-tabs {
  display: flex !important;
  overflow: hidden !important;
  min-height: 48px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 6px 12px !important;
  font-size: 1rem !important;
  box-sizing: border-box !important;
  min-height: 48px !important;
  text-align: center !important;
  flex-shrink: 0 !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  white-space: normal !important;
  text-transform: lowercase !important;
}

.nav-item {
  min-width: 130px !important;
  padding-inline: 0 !important;
  align-items: center !important;
  user-select: none !important;
  border-radius: 0 !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  justify-content: center !important;
  text-decoration: none !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important;
  border: 0 !important;
  display: inline-flex !important;
  border: 0px !important;
}

.nav-link.active {
  color: #f45000 !important;
  border: 0px !important;
  border-bottom: 2px solid #f45000 !important;
}

.nav-link:hover {
  color: #f45000 !important;
  border: 0px !important;
  border-bottom: 2px solid #f45000 !important;
}

.form-control {
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: text !important;
  display: inline-flex !important;
  position: relative !important;
  font-size: 1.125rem !important;
  box-sizing: border-box !important;
  align-items: center !important;
  font-weight: 400 !important;
  line-height: 1.1876em !important;
  border-radius: 0 !important;
}

.badge {
  border: none !important;
  cursor: default !important;
  height: 32px !important;
  display: inline-flex !important;
  outline: 0 !important;
  padding: 8px !important;
  font-size: 0.8125rem !important;
  box-sizing: border-box !important;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  align-items: center !important;
  white-space: nowrap !important;
  border-radius: 16px !important;
  vertical-align: middle !important;
  justify-content: center !important;
  text-decoration: none !important;
}

.alert {
  background-color: #f6f6f6 !important;
  display: flex !important;
  padding: 6px 16px !important;
  font-size: 1.1rem !important;
  font-weight: 550 !important;
  line-height: 120% !important;
}

.alert-info {
  background: #f6f6f6 !important;
  color: #000 !important;
  border: 0px !important;
  border-left: 3px solid #000 !important;
}
